import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './custom.scss';

import GatewayProvider from 'providers/GatewayProvider';
import ServiceWorkerStatusProvider from 'shared-components/providers/ServiceWorkerStatusProvider';
import SWEventProvider from 'shared-components/providers/SWEventProvider';
import { WebsocketLogger } from 'shared-components/utils/logger';
import * as serviceWorker from 'shared-components/serviceWorker';
import { StateMachineProvider } from 'providers/StateMachineProvider';

import router from './router';

serviceWorker.register();

declare global {
  interface Window {
    App: any;
    console: any;
  }
  let App: any;
}
window.App = window.App || ({} as { channel: BroadcastChannel });
const weblogger = WebsocketLogger();

App.logData = (data: object) => {
  console.log(`LOGGER: ${JSON.stringify(data)}`);
};

window.addEventListener('error', function (error) {
  console.error(error);
  App.logData(error.message);
  error.preventDefault();
});

App.channel = new BroadcastChannel('merchant-sw');

App.logger = new BroadcastChannel('logger');
App.logger.addEventListener('message', (event: MessageEvent<any>) => {
  const data = JSON.stringify(event.data);
  console.log(data);
  weblogger.sendLog(data);
});

App.sendMessage = (message: string) => {
  return new Promise(function (resolve, reject) {
    const messageChannel = new MessageChannel();
    messageChannel.port1.addEventListener('message', function (event) {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    });
    navigator.serviceWorker?.controller?.postMessage(message, [messageChannel.port2]);
  });
};

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);

root.render(
  <CookiesProvider>
    <StateMachineProvider>
      <GatewayProvider>
        <ServiceWorkerStatusProvider>
          <SWEventProvider>
            <RouterProvider router={router} future={{ v7_startTransition: true }} fallbackElement={<div>test</div>} />
          </SWEventProvider>
        </ServiceWorkerStatusProvider>
      </GatewayProvider>
    </StateMachineProvider>
  </CookiesProvider>
);
